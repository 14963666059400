import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuthenticated: false,
  user: null,
  userProfile: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    register: (state, action) => {
      // Assume action.payload contains the user details
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    login: (state, action) => {
      // Assume action.payload contains the user details
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.userProfile = null;
    },
    setUserProfile: (state, action) => {
      state.userProfile = action.payload;
    }
  },
});

export const { register, login, logout, setUserProfile } = authSlice.actions;
export default authSlice.reducer;
