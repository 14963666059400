import CryptoJS from 'crypto-js';
import { setCurrentUser } from "../Redux/Slices/authSlice";
import { store } from '../Redux/Store.js';

const encKey = process.env.REACT_APP_ENCRYPTION_KEY;
const baseUrl = process.env.REACT_APP_BASE_URL;
const loginUrl = process.env.REACT_APP_LANDING_URL;
const DEFAULT_CACHE_DURATION_MS = 12 * 60 * 60 * 1000; // 12 hours in milliseconds

// Cache durations for specific items (in milliseconds)
const CACHE_DURATIONS = {
  jwt: 24 * 60 * 60 * 1000,  // 24 hours
  userDetails: 7 * 24 * 60 * 60 * 1000,  // 7 days
  allCourses: 3 * 24 * 60 * 60 * 1000,  // 3 days
  python: 14 * 24 * 60 * 60 * 1000, // 14 days
  react: 14 * 24 * 60 * 60 * 1000, // 14 days
  // Add more items as needed
};

  // validate jwt, if jwt does not meet the criteria, return true, !jwt || jwt === '' || (typeof jwt === 'object' && Object.keys(jwt).length === 0)
  export const validateJwt = (jwt) => {
    return jwt && typeof jwt === 'string' && jwt.trim() !== '' && jwt !== '{}' && jwt.constructor === String;
  };

export const levelSwap = {
  1: "Novice",
  2: "Intermediate",
  3: "Senior",
  4: "Tech Lead",
  5: "Architect"
};

// JWT Handling
export const verifyJWT = async (jwt) => {
  // Check if jwt is null, undefined, an empty string, or an empty object
  if (validateJwt(jwt)) {
    console.warn('Invalid JWT provided. Skipping verification.');
    clearCacheContent('jwt', 'localStorage');
    throw new Error('Invalid JWT');
  }

  try {
    const response = await fetch(`${baseUrl}/auth/verify`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify({ token: jwt }),
    });
    
    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      throw new Error(`Token verification failed: ${errorData.message || response.statusText}`);
    }
    
    const { token } = await response.json();
    const cleanToken = token.replace('Bearer ', '');
    setCacheContent('jwt', cleanToken, 'localStorage');
    return { token: cleanToken };
  } catch (error) {
    console.error('Error in verifyJWT:', error);
    clearCacheContent('jwt', 'localStorage');
    throw error;
  }
};

/// Cache Management
const cacheImplementations = {
  localStorage: {
    set: (key, content) => localStorage.setItem(key, JSON.stringify(content)),
    get: (key) => {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : null;
    },
    clear: (key) => localStorage.removeItem(key),
  },
  sessionStorage: {
    set: (key, content) => sessionStorage.setItem(key, JSON.stringify(content)),
    get: (key) => {
      const item = sessionStorage.getItem(key);
      return item ? JSON.parse(item) : null;
    },
    clear: (key) => sessionStorage.removeItem(key),
  },
};

export const setCacheContent = (key, content, storageType = 'localStorage') => {
  const timestamp = new Date().getTime();
  const cacheData = {
    timestamp,
    content: encryptContent(content),
    duration: CACHE_DURATIONS[key] || DEFAULT_CACHE_DURATION_MS,
  };
  cacheImplementations[storageType].set(key, cacheData);
};

export const getCachedContent = (key, storageType = 'localStorage') => {
  if (!cacheImplementations[storageType]) {
    console.error(`Invalid storage type: ${storageType}`);
    return null;
  }
  
  const cacheData = cacheImplementations[storageType].get(key);
  if (!cacheData) return null;
  
  const now = new Date().getTime();
  const cacheDuration = cacheData.duration || DEFAULT_CACHE_DURATION_MS;
  if (now - cacheData.timestamp > cacheDuration) {
    clearCacheContent(key, storageType);
    return null;
  }
  
  return decryptContent(cacheData.content);
};

export const clearCacheContent = (key, storageType = 'localStorage') => {
  cacheImplementations[storageType].clear(key);
};

const encryptContent = (content) => 
  CryptoJS.AES.encrypt(JSON.stringify(content), encKey).toString();

const decryptContent = (content) => {
  const bytes = CryptoJS.AES.decrypt(content, encKey);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

// Currency and Country Handling
const currencyMap = {
  NGN: "₦", GHS: "GH₵", KES: "KSh", CAD: "C$", ZAR: "R", MAD: "MAD",
  RWF: "FRw", DZD: "DA", EGP: "E£", USD: "$", EUR: "€", GBP: "£"
};

const countryMap = {
  Nigeria: "NGN", Ghana: "GHS", Kenya: "KES", Canada: "CAD",
  "South Africa": "ZAR", "RSA": "ZAR", "SA": "ZAR",
  Morocco: "MAD", Rwanda: "RWF", Algeria: "DZD", Egypt: "EGP",
  "United States": "USD", "US": "USD", "USA": "USD",
  "United Kingdom": "GBP", "UK": "GBP", Europe: "EUR"
};

export const currencySymbol = (currency) => currencyMap[currency] || currency;
export const countryToCurrency = (country) => countryMap[country] || "USD";

// Utility Functions
export const totalProgress = (userProgress) => {
  if (!userProgress || typeof userProgress !== 'object') {
    return 0;
  }

  return Object.values(userProgress)
    .filter(level => level && typeof level === 'object')
    .reduce((acc, level) => acc + (level.progress ?? 0), 0);
};

export const logOut = () => {
  document.cookie = 'jwt=;path=/;';
  ['jwt', 'allCourses', 'course', 'userdetails'].forEach(key => {
    clearCacheContent(key, 'localStorage');
    clearCacheContent(key, 'sessionStorage');
  });
  
  const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  for(let key in localStorage) {
    if (uuidPattern.test(key)) {
      clearCacheContent(key, 'localStorage');
    }
  }
  
  window.location.href = loginUrl;
};