import { setCacheContent } from "./helper";

export const getLocationInfo = async (latitude, longitude) => {
  const APIkey = process.env.REACT_APP_OPENCAGE_API_KEY;
  const url = `https://api.opencagedata.com/geocode/v1/json?q=${latitude},${longitude}&key=${APIkey}`;
  fetch(url)
    .then((response) => response.json())
    .then((data) => {
      if (data.status.code === 200) {
        setCacheContent("location", data.results[0].formatted);
        setCacheContent(
          "country",
          data.results[0].components.country
        );
      } else {
        console.log("Reverse geolocation request failed.");
      }
    })
    .catch((error) => console.error(error));
};

export const geoSteps = async () => {
  if (navigator.geolocation) {
    navigator.permissions
      .query({ name: "geolocation" })
      .then(function (result) {
        // console.log(result);
        if (result.state === "granted") {
          //If granted then you can directly call your function here
          navigator.geolocation.getCurrentPosition(success, errors, options);
        } else if (result.state === "prompt") {
          //If prompt then the user will be asked to give permission
          navigator.geolocation.getCurrentPosition(success, errors, options);
        } else if (result.state === "denied") {
          //If denied then you have to show instructions to enable location
          alert("Please enable location to get the best experience");
        }
      });
  } else {
    console.log("Geolocation is not supported by this browser.");
  }
};

var options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};
export const success = async (pos) => {
  var crd = pos.coords;
  // console.log("Your current position is:");
  // console.log(`Latitude : ${crd.latitude}`);
  // console.log(`Longitude: ${crd.longitude}`);
  // console.log(`More or less ${crd.accuracy} meters.`);
  getLocationInfo(crd.latitude, crd.longitude);
};

export const errors = async (err) => {
  console.warn(`ERROR(${err.code}): ${err.message}`);
};

export const fetchIp = async () => {
  try {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    setCacheContent("ipAddress", data.ip);
    // find country by ip address
    const countryResponse = await fetch(`https://ipapi.co/${data.ip}/json/`);
    const countryData = await countryResponse.json();
    setCacheContent("country", countryData.country_name);
  } catch (error) {
    console.error(error);
  }
};
