import React from "react";
import { Navigate } from "react-router-dom";
import Course from "../pages/Course/index";

//Utility
import PagePrivacy from "../pages/Pages/Utility/PagePrivacy";
import PageTerms from "../pages/Pages/Utility/PageTerms";
//Auth Pages


const routes = [
  //Index Main
  {
    path: "/",
    exact: true,
    component: <Navigate to="/index" />,
  },
  { path: "/index", component: <Course />, isTopbarDark: true },
  
    //Utility
    { path: "/terms", component: <PageTerms />, isTopbarDark: true },
    { path: "/privacy", component: <PagePrivacy />, isTopbarDark: true },
  
];

export default routes;
