import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [],
  totalQuantity: 0,
  totalPrice: 0,
  showModal: false,
  courseIds: [],
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addItem: (state, action) => {
      const existingItem = state.items.find(item => item.id === action.payload.id);
      if (!existingItem) {
        state.items.push({ ...action.payload, quantity: 1 });
        state.totalQuantity += 1;
        state.totalPrice += Number(action.payload.price);
        state.courseIds.push(action.payload.id); // Add courseId to courseIds array when item is added
      }
    },
    removeItem: (state, action) => {
      const existingItem = state.items.find(item => item.id === action.payload.id);
      if (existingItem) {
        state.items = state.items.filter(item => item.id !== action.payload.id);
        state.totalQuantity -= 1;
        state.totalPrice -= existingItem.price * existingItem.quantity;
        state.courseIds = state.courseIds.filter(id => id !== action.payload.id); // Remove courseId from courseIds array
      }
    },
    clearCart: (state) => {
      state.items = [];
      state.totalQuantity = 0;
      state.totalPrice = 0;
      state.courseIds = []; // Clear courseIds array when cart is cleared
    },
    calculateTotals: (state) => {
      state.totalQuantity = state.items.reduce((total, item) => total + item.quantity, 0);
      state.totalPrice = state.items.reduce((total, item) => total + item.price * item.quantity, 0);
    },
    setShowModal: (state, action) => {
      state.showModal = action.payload;
    },
    setCourseIds: (state, action) => {
      state.courseIds = action.payload;
    },
  },
});

export const { addItem, removeItem, clearCart, calculateTotals, setShowModal, setCourseIds } = cartSlice.actions;
export default cartSlice.reducer;
