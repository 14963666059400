// React Basic and Bootstrap
import React, {Component } from "react";
import Services from "./Services";
import Section from "./section";

class Index extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {

    document.body.classList = "";
    document.querySelectorAll("#buyButton").forEach((navLink) => {
      navLink.classList.add("btn-light");
      navLink.classList.remove("btn-soft-primary");
      document.getElementById("top-menu")?.classList.add("nav-light");
    });
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      document.querySelector(".shoppingbtn")?.classList.remove("btn-light");
      // document.querySelector(".settingbtn")?.classList.remove("btn-light");
      document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
      // document.querySelector(".settingbtn")?.classList.add("btn-soft-primary");
      document.getElementById("topnav")?.classList.add("nav-sticky");
    } else {
      document.querySelector(".shoppingbtn")?.classList.remove("btn-primary");
      // document
      //   .querySelector(".settingbtn")
      //   ?.classList.remove("btn-soft-primary");
      document.querySelector(".shoppingbtn")?.classList.add("btn-light");
      // document.querySelector(".settingbtn")?.classList.add("btn-light");
      document.getElementById("topnav")?.classList.remove("nav-sticky");
    }
  };

  // Scroll function
  scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  render() {
    return (
      <React.Fragment>
        {/* Hero Start */}
        <Section scrollToSection={this.scrollToSection} />

        {/* Feature */}
        {/* <Feature /> */}

        {/* Services */}
        <Services />

        {/* Cta1 */}
        {/* <Cta1 /> */}

        {/* Testi */}
        {/* <Testi /> */}

        {/* <Popup /> */}
      </React.Fragment>
    );
  }
}

export default Index;
