import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import Slider from "react-slick";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import bg01 from "../../assets/images/course/steps.svg";
import bg04 from "../../assets/images/course/coding1.svg";


class Section extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [
        {
          image: bg01,
          title: "Avoid tutorial hell.",
          desc: "Easiest way to learn, then land your dream job.",
          btnText: "Find Courses",
          btnLink: "courses",
          icon: "mdi-book-open-variant",
        },
        {
          image: bg04,
          title: "Learn by building awesome projects.",
          desc: "Show off your projects to land jobs.",
          btnText: "Explore Courses",
          btnLink: "courses",
          icon: "mdi-school",
        }
      ],
      autoplay: true,
    };
  }

  componentDidMount() {
    var e1 = document.getElementsByClassName("slick-slide");
    for (var i = 0; i < 2; i++) {
      if (i === 0) e1[i].style.backgroundImage = `url(${bg01})`;
      if (i === 1) e1[i].style.backgroundImage = `url(${bg04})`;
    }
  }

  handleButtonClick = (e, link) => {
    e.preventDefault();
    const { scrollToSection } = this.props;
    const sectionId = link.replace("#", "");
    scrollToSection(sectionId);
  };

  render() {
    var settings = {
      autoplay: true,
      infinite: true,
      autoplaySpeed: 6800,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      draggable: true,
      pauseOnHover: true,
    };

    return (
      <React.Fragment>
        <style>
        {`
            .main-slider {
              position: relative;
              overflow: hidden;
            }
            .bg-home {
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
              position: relative;
              height: 40vh;
              min-height: 300px;
            }
            .bg-overlay {
              background-color: rgba(0,0,0,0.6);
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              z-index: 1;
            }
            .slider-content {
              position: absolute;
              top: 50%;
              left: 0;
              right: 0;
              transform: translateY(-50%);
              z-index: 2;
            }
            @media (max-width: 768px) {
              .bg-home {
                height: 300px;
              }
              .slider-title {
                font-size: 1.5rem !important;
              }
              .slider-desc {
                font-size: 0.8rem !important;
              }
              .slider-button {
                font-size: 0.7rem !important;
                padding: 0.3rem 0.6rem !important;
              }
            }
            @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
              .bg-home {
                height: 450px;
              }
              .slider-title {
                font-size: 1.8rem !important;
              }
              .slider-desc {
                font-size: 1rem !important;
                max-width: 90% !important;
              }
              .slider-button {
                font-size: 0.9rem !important;
                padding: 0.5rem 1rem !important;
              }
            }
            @media (min-width: 1024px) and (max-width: 1280px) {
              .bg-home {
                height: 500px;
              }
              .slider-title {
                font-size: 2rem !important;
              }
              .slider-desc {
                font-size: 1.1rem !important;
                max-width: 90% !important;
              }
              .slider-button {
                font-size: 1rem !important;
                padding: 0.6rem 1.2rem !important;
              }
            }
            @media (min-width: 1280px) {
              .bg-home {
                height: 600px;
              }
              .slider-title {
                font-size: 2.2rem !important;
              }
              .slider-desc {
                font-size: 1.2rem !important;
                max-width: 90% !important;
              }
              .slider-button {
                font-size: 1.1rem !important;
                padding: 0.7rem 1.4rem !important;
              }
            }
          `}
        </style>
        <section className="main-slider" style={{ overflow: "hidden", position: "relative" }}>
          <Slider className="slides" {...settings}>
            {this.state.items.map((item, key) => (
              <li
                key={key}
                className="bg-home"
                style={{ 
                  backgroundImage: `url(${item.image})`,
                  backgroundSize: "contain",
                  objectFit: "cover",
                  backgroundPosition: "center",
                  height: "40vh",
                  position: "relative"
                }}
              >
                <div className="bg-overlay" style={{ backgroundColor: 'rgba(0,0,0,0.6)', position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}></div>
                <Container className="slider-content" style={{ position: "absolute", top: "20vh", left: 0, right: 0 }}>
                  <Row className="justify-content-center">
                    <Col xs="12" className="text-center">
                      <div className="title-heading text-white">
                        <h1 className="text-white fw-bold mb-2 slider-title" style={{ fontSize: '1.8rem' }}>
                          {item.title}
                        </h1>
                        <p className="text-white-75 mx-auto mb-2 slider-desc" style={{ fontSize: '0.9rem', maxWidth: '80%' }}>
                          {item.desc}
                        </p>
                        <div>
                          <Link
                            to={item.btnLink}
                            className="btn btn-primary mouse-down slider-button"
                            onClick={(e) => this.handleButtonClick(e, item.btnLink)}
                            style={{ fontSize: '0.8rem', padding: '0.4rem 0.8rem' }}
                          >
                            <i className={"mdi " + item.icon}></i>{" "}
                            {item.btnText}
                          </Link>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </li>
            ))}
          </Slider>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;