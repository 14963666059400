// React Basic and Bootstrap
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Collapse,
} from "reactstrap";

//Import Icons
import FeatherIcon from "feather-icons-react";

class PageTerms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
      col1: true,
      col2: false,
      col3: false,
      col4: false,
      col5: false,
    };
    this.t_col1 = this.t_col1.bind(this);
    this.t_col2 = this.t_col2.bind(this);
    this.t_col3 = this.t_col3.bind(this);
    this.t_col4 = this.t_col4.bind(this);
    this.t_col5 = this.t_col5.bind(this);
  }

  t_col1() {
    this.setState({
      col1: !this.state.col1,
      col2: false,
      col3: false,
      col4: false,
      col5: false,
    });
  }
  t_col2() {
    this.setState({
      col2: !this.state.col2,
      col1: false,
      col3: false,
      col4: false,
      col5: false,
    });
  }
  t_col3() {
    this.setState({
      col3: !this.state.col3,
      col2: false,
      col1: false,
      col4: false,
      col5: false,
    });
  }
  t_col4() {
    this.setState({
      col4: !this.state.col4,
      col2: false,
      col3: false,
      col1: false,
      col5: false,
    });
  }
  t_col5() {
    this.setState({
      col5: !this.state.col5,
      col2: false,
      col3: false,
      col1: false,
      col4: false,
    });
  }

  componentDidMount() {
    document.body.classList = "";
    document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById("topnav");
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add("nav-sticky");
      } else {
        navBar.classList.remove("nav-sticky");
        document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
        document.querySelector(".shoppingbtn")?.classList.remove("btn-light");
        document.querySelector(".settingbtn")?.classList.add("btn-soft-primary");
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        {/* breadcrumb */}
        <section className="bg-half-170 bg-light d-table w-100">
          <Container>
            <Row className="mt-5 justify-content-center">
              <Col lg={12} className="text-center">
                <div className="pages-heading">
                  <h4 className="title mb-0"> Terms of Services </h4>
                </div>
              </Col>
            </Row>

            <div className="position-breadcrumb">
              <nav aria-label="breadcrumb" className="d-inline-block">
                <ul className="breadcrumb rounded shadow mb-0 px-4 py-2">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>{" "}
                  <li className="breadcrumb-item active" aria-current="page">Terms</li>
                </ul>
              </nav>
            </div>
          </Container>
        </section>
        <div className="position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>

        <section className="section">
          <Container>
            <Row className="justify-content-center">
              <Col lg={9}>
                <Card className="shadow border-0 rounded">
                  <CardBody>

            <p className='text-center'><strong>Effective Date:</strong> 01-01-2022</p>

            <section className="mb-4">
              <h3>1. Introduction</h3>
              <p>Welcome to CodesWithVic ("Platform", "we", "us", "our"). These Terms and Conditions ("Terms") govern your use of our website, services, and content provided by CodesWithVic ("Company"). By accessing or using our Platform, you agree to be bound by these Terms. If you do not agree with any part of these Terms, please do not use our Platform.</p>
            </section>

            <section className="mb-4">
              <h3>2. Use of the Platform</h3>
              <ul>
                <li><strong>Eligibility:</strong> You must be at least 18 years old to use our Platform. By using our Platform, you represent and warrant that you meet this age requirement.</li>
                <li><strong>Account Registration:</strong> To access certain features, you may be required to register for an account. You agree to provide accurate and complete information during registration and to update such information to keep it accurate and complete.</li>
                <li><strong>Account Security:</strong> You are responsible for maintaining the confidentiality of your account login information and for all activities that occur under your account. Notify us immediately of any unauthorized use of your account or any other breach of security.</li>
              </ul>
            </section>

            <section className="mb-4">
              <h3>3. Content</h3>
              <ul>
                <li><strong>Ownership:</strong> All content provided on our Platform, including but not limited to text, graphics, logos, images, and videos, is the property of CodesWithVic or its content suppliers and is protected by intellectual property laws.</li>
                <li><strong>User Content:</strong> You retain ownership of any content you submit to our Platform. By submitting content, you grant us a worldwide, royalty-free, perpetual, and non-exclusive license to use, reproduce, modify, and distribute such content in connection with our Platform.</li>
              </ul>
            </section>

            <section className="mb-4">
              <h3>4. Prohibited Conduct</h3>
              <ul>
                <li>You agree not to use the Platform for any illegal or unauthorized purpose.</li>
                <li>You agree not to interfere with or disrupt the Platform or servers.</li>
                <li>You agree not to transmit any malicious software, viruses, or harmful code.</li>
                <li>You agree not to attempt to gain unauthorized access to any part of the Platform.</li>
              </ul>
            </section>

            <section className="mb-4">
              <h3>5. Paid Services</h3>
              <ul>
                <li><strong>Fees:</strong> Some features of the Platform may require payment. All fees are described on our Platform and are subject to change.</li>
                <li><strong>Refunds:</strong> Refunds for paid services will be provided in accordance with our refund policy, which can be found [here/at a specific location].</li>
              </ul>
            </section>

            <section className="mb-4">
              <h3>6. Termination</h3>
              <p>We reserve the right to terminate or suspend your account and access to the Platform if you violate these Terms or for any other reason at our sole discretion.</p>
            </section>

            <section className="mb-4">
              <h3>7. Disclaimer of Warranties</h3>
              <p>The Platform is provided "as is" and "as available" without any warranties of any kind, whether express or implied. We do not warrant that the Platform will be uninterrupted, secure, or error-free.</p>
            </section>

            <section className="mb-4">
              <h3>8. Limitation of Liability</h3>
              <p>To the fullest extent permitted by law, CodesWithVic shall not be liable for any indirect, incidental, special, or consequential damages arising from your use of the Platform.</p>
            </section>

            <section className="mb-4">
              <h3>9. Governing Law</h3>
              <p>These Terms are governed by and construed in accordance with the laws of Nigeria or Canada. Any disputes arising from these Terms or your use of the Platform shall be resolved in the courts located in Nigeria or Canada.</p>
            </section>

            <section className="mb-4">
              <h3>10. Changes to These Terms</h3>
              <p>We reserve the right to modify these Terms at any time. Any changes will be posted on our Platform, and your continued use of the Platform constitutes your acceptance of the revised Terms.</p>
            </section>

            <section className="mb-4">
              <h3>11. Contact Us</h3>
              <p>If you have any questions or concerns about these Terms, please contact us at:</p>
              <ul>
                <li><strong>CodesWithVic</strong></li>
                <li><strong>Address:</strong> Abuja, Nigeria & Toronto, Canada</li>
                <li><strong>Email Address:</strong> hello@codeswithvic.com</li>
                <li><strong>Phone Number:</strong> 08138257990</li>
              </ul>
            </section>
                   

                    {/* <h5 className="card-title">Restrictions :</h5>
                    <p className="text-muted">
                      You are specifically restricted from all of the following
                      :
                    </p>
                    <ul className="list-unstyled feature-list text-muted">
                      <li>
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2"
                          />
                        </i>
                        Digital Marketing Solutions for Tomorrow
                      </li>
                      <li>
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2"
                          />
                        </i>
                        Our Talented & Experienced Marketing Agency
                      </li>
                      <li>
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2"
                          />
                        </i>
                        Create your own skin to match your brand
                      </li>
                      <li>
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2"
                          />
                        </i>
                        Digital Marketing Solutions for Tomorrow
                      </li>
                      <li>
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2"
                          />
                        </i>
                        Our Talented & Experienced Marketing Agency
                      </li>
                      <li>
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2"
                          />
                        </i>
                        Create your own skin to match your brand
                      </li>
                    </ul>

                    <h5 className="card-title">Users Question & Answer :</h5>
                    <div className="faq-content mt-4">
                      <div className="accordion" id="accordionExample">
                        <Card className="border-0 rounded mb-2">
                          <Link
                            to="#"
                            onClick={this.t_col1}
                            className={
                              this.state.col1
                                ? "faq position-relative text-primary"
                                : "faq position-relative text-dark"
                            }
                          >
                            <CardHeader
                              className="border-0 bg-light p-3"
                              id="headingOne"
                            >
                              <h6 className="title mb-0">
                                How does it work ?
                                <i
                                  className={
                                    this.state.col1
                                      ? "mdi mdi-chevron-up float-end"
                                      : "mdi mdi-chevron-down float-end"
                                  }
                                ></i>
                              </h6>
                            </CardHeader>
                          </Link>
                          <Collapse isOpen={this.state.col1}>
                            <CardBody>
                              <p className="text-muted mb-0 faq-ans">
                                There are many variations of passages of Lorem
                                Ipsum available, but the majority have suffered
                                alteration in some form.
                              </p>
                            </CardBody>
                          </Collapse>
                        </Card>

                        <Card className="border-0 rounded mb-2">
                          <Link
                            to="#"
                            onClick={this.t_col2}
                            className={
                              this.state.col2
                                ? "faq position-relative text-primary"
                                : "faq position-relative text-dark"
                            }
                          >
                            <CardHeader
                              className="border-0 bg-light p-3"
                              id="headingTwo"
                            >
                              <h6 className="title mb-0">
                                Do I need a designer to use Landrick ?
                                <i
                                  className={
                                    this.state.col2
                                      ? "mdi mdi-chevron-up float-end"
                                      : "mdi mdi-chevron-down float-end"
                                  }
                                ></i>
                              </h6>
                            </CardHeader>
                          </Link>
                          <Collapse isOpen={this.state.col2}>
                            <CardBody>
                              <p className="text-muted mb-0 faq-ans">
                                There are many variations of passages of Lorem
                                Ipsum available, but the majority have suffered
                                alteration in some form.
                              </p>
                            </CardBody>
                          </Collapse>
                        </Card>

                        <Card className="border-0 rounded mb-2">
                          <Link
                            to="#"
                            onClick={this.t_col3}
                            className={
                              this.state.col3
                                ? "faq position-relative text-primary"
                                : "faq position-relative text-dark"
                            }
                          >
                            <CardHeader
                              className="border-0 bg-light p-3"
                              id="headingfive"
                            >
                              <h6 className="title mb-0">
                                What do I need to do to start selling ?
                                <i
                                  className={
                                    this.state.col3
                                      ? "mdi mdi-chevron-up float-end"
                                      : "mdi mdi-chevron-down float-end"
                                  }
                                ></i>
                              </h6>
                            </CardHeader>
                          </Link>
                          <Collapse isOpen={this.state.col3}>
                            <CardBody>
                              <p className="text-muted mb-0 faq-ans">
                                There are many variations of passages of Lorem
                                Ipsum available, but the majority have suffered
                                alteration in some form.
                              </p>
                            </CardBody>
                          </Collapse>
                        </Card>

                        <Card className="border-0 rounded mb-2">
                          <Link
                            to="#"
                            onClick={this.t_col4}
                            className={
                              this.state.col4
                                ? "faq position-relative text-primary"
                                : "faq position-relative text-dark"
                            }
                          >
                            <CardHeader
                              className="border-0 bg-light p-3"
                              id="headingfive"
                            >
                              <h6 className="title mb-0">
                                What happens when I receive an order ?
                                <i
                                  className={
                                    this.state.col4
                                      ? "mdi mdi-chevron-up float-end"
                                      : "mdi mdi-chevron-down float-end"
                                  }
                                ></i>
                              </h6>
                            </CardHeader>
                          </Link>
                          <Collapse isOpen={this.state.col4}>
                            <CardBody>
                              <p className="text-muted mb-0 faq-ans">
                                There are many variations of passages of Lorem
                                Ipsum available, but the majority have suffered
                                alteration in some form.
                              </p>
                            </CardBody>
                          </Collapse>
                        </Card>                        
                      </div>
                    </div>

                    <div className="mt-3">
                      <Link to="#" className="btn btn-primary mt-2 me-2">
                        Accept
                      </Link>{" "}
                      <Link to="#" className="btn btn-outline-primary mt-2">
                        Decline
                      </Link>
                    </div> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
export default PageTerms;
